/**
 * @name objectSpread
 * @summary Concats all sources into the destination
 */
export function objectSpread(dest, ...sources) {
  for (let i = 0, count = sources.length; i < count; i++) {
    const src = sources[i];
    if (src) {
      if (typeof src.entries === 'function') {
        for (const [key, value] of src.entries()) {
          dest[key] = value;
        }
      } else {
        Object.assign(dest, src);
      }
    }
  }
  return dest;
}