export const environment = {
    network: [
        {
          networks: [
            {
              id: 1,
              name: 'Mainnet',
              wsProviderEndpoint: 'wss://rpcnodea01.xode.net/n7yoxCmcIrCF6VziCcDmYTwL8R03a/rpc',
              domain: 'https://dashboard.xgame.live',
              net_name: 'MAINNET',
              is_default: false
            }
          ]
        },
        {
          networks: [
            {
              id: 2,
              name: 'Testnet',
              wsProviderEndpoint: 'wss://testrpcnodea01.xode.net/aRoyklGrhl9m2LlhX8NP/rpc' ,
              domain: 'https://dashboard-xgame.staginglab.info',
              net_name: 'TESTNET',
              is_default: true
            }
          ]
        },
    ],
    TeamAPIURL: 'https://teams-xgame-api.staginglab.info',
    AstroChibbiAPIURL: 'https://astrochibbi-xgame-api.staginglab.info',
    WalletAPIURL: 'https://wallet-xode-api.staginglab.info',
    FacebookURL: 'https://www.instagram.com/fornexgenofficial',
    InstagramURL: 'https://www.instagram.com/fornexgenofficial',
    LinkedInURL: 'https://www.linkedin.com/company/pinoycarevisa/',

      // secret_key
    secret_key: "^a7T&kPzL#9s@4!gF%8H",

    // URL to set session
    AllURL: [
        { url: "xode.staginglab.info" },
        { url: "xgame.staginglab.info" },
        { url: "astrochibbi-xgame.staginglab.info" },
        { url: "fruitblitz-xgame.staginglab.info" },
        { url: "backoffice-xgame.staginglab.info" },
        { url: "marketplace-xgame.staginglab.info" },
        { url: "dashboard-xgame.staginglab.info" },
        { url: "mint-xgame.staginglab.info" },
        { url: "exchang-xgame.staginglab.info" },
    ],

    gameAPIURL: "https://product-pages-xgame-api.staginglab.info/",
    blogAPIURL: "https://blogs-xgame-api.staginglab.info/",

    astroCollectionId: "5FJ9VWpubQXeiLKGcVmo3zD627UAJCiW6bupSUATeyNXTH1m"
};
