import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BreadcrumbsComponent } from '../components/breadcrumbs/breadcrumbs.component';
import { FormsModule } from '@angular/forms';
import { SafePipe } from '../pipes/safe.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { ConnectWalletComponent } from '../components/connect-wallet/connect-wallet.component';
import { PolkadotIdenticonComponent } from '../components/polkadot-identicon/polkadot-identicon.component';

// src/app/module/shared/shared.module.ts
@NgModule({
    declarations: [
      BreadcrumbsComponent,
      SafePipe,
      ConnectWalletComponent,
      PolkadotIdenticonComponent
    ],
    imports: [
      CommonModule,
      FormsModule,
      TranslateModule,
    ],
    exports: [
      BreadcrumbsComponent,
      SafePipe,
      TranslateModule,
      ConnectWalletComponent,
      PolkadotIdenticonComponent
    ]
  })
  export class SharedModule { }
