import { Component } from '@angular/core';
import { PolkadotService } from '../../services/polkadot/polkadot.service';
import { ErrorWeb3Wallet, WalletAccountsModel } from '../../model/wallet.model';
import { CookiesService } from '../../services/cookies/cookies.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-connect-wallet',
  templateUrl: './connect-wallet.component.html',
  styleUrl: './connect-wallet.component.scss'
})
export class ConnectWalletComponent {
  web3WalletArray: any[] = [];
  selectedWalletAccount: WalletAccountsModel = new WalletAccountsModel();
  isWalletSelected: boolean = false;
  isLoading: boolean = false;
  iframeSrc: string = "";

  walletInfo: any = "";

  isModalVisible: boolean = false; // Property to track modal visibility


  walletAccounts: WalletAccountsModel[] = [];
  errorWeb3Wallet: ErrorWeb3Wallet = new ErrorWeb3Wallet();

  constructor(
    private polkadotService: PolkadotService,
    private cookiesService: CookiesService,
  ) {
    this.walletInfo = this.cookiesService.getCookieArray('wallet-info');
  }

  pageSweetAlert(success: boolean, swalTitle: string, swalText: string) {
    const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        icon: "success",
        showConfirmButton: false,
        timer: 1500,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        },
        didClose: () => {
            window.location.reload();
        },
    })
    Swal.fire({
        icon: success ? 'success' : 'error',
        title: swalTitle,
        text: swalText
    }).then((result)=>{
      if(result.isConfirmed){
        location.reload();
      }
    })
  }

  goBack(){
    this.isWalletSelected = !this.isWalletSelected;
  }
  getAllExtension(){
    let allExtension = this.polkadotService.getAllExtension();
    this.web3WalletArray = allExtension;
  }

  connectExtension(extension: string){

    this.walletAccounts = [];
    this.polkadotService.connectExtension(extension)
    .then((walletAccounts) => {
      this.errorWeb3Wallet = new ErrorWeb3Wallet();
      this.walletAccounts = walletAccounts;
      this.isWalletSelected = true;
      // Do something with walletAccounts
    })
    .catch((error) => {
      if(this.errorWeb3Wallet.extensionName != extension){
        this.errorWeb3Wallet = new ErrorWeb3Wallet();
      }
      this.errorWeb3Wallet.extensionName = extension;
      if (error.toString().includes("Talisman extension has not been configured yet. Please continue with onboarding")) {
        this.errorWeb3Wallet.errorCode = "400";
        this.errorWeb3Wallet.errorMessage = error.message;
      }else if(error.toString().includes("Refresh the browser if Polkadot.js is already installed.")){
        this.errorWeb3Wallet.errorCode = "404";
        this.errorWeb3Wallet.errorMessage = "We can't find your Polkadot.js extension, Download Polkadot.js extension";
      }else if(error.toString().includes("Refresh the browser if Talisman is already installed.")){
        this.errorWeb3Wallet.errorCode = "404";
        this.errorWeb3Wallet.errorMessage = "We can't find your Talisman extension, Download Talisman extension";
      }

      // console.error('Error connecting extension:', error);
    });
  }

  async onWalletSelectAndVerify(walletAccount: WalletAccountsModel) {
    try{
      this.isLoading = true;
      let signAndVerify: boolean = await this.polkadotService.signAndVerify(walletAccount);
      if (signAndVerify) {
        let keypair = await this.polkadotService.generateKeypair(walletAccount.address);
        if (keypair != "") {
          // this.selectedWalletAccount.tokenSymbol = await this.polkadotService.getChainTokens();
          this.cookiesService.setCookieArray("wallet-info",walletAccount);
          this.pageSweetAlert(true, 'Connected', 'Your wallet was connected successfully.')
        }
        this.isLoading = false;
      }else{
        this.isLoading = false;
      }
      this.isLoading = false;
    }catch(error){
      console.log("Error", error)
    }
  }

  toggleModal(): void {
    this.isModalVisible = !this.isModalVisible;
  }
  disconnect(){
    this.cookiesService.deleteAllCookie();
    window.location.reload();
  }

  ngOnInit(): void {
    this.getAllExtension();
    if(this.cookiesService.getCookieArray('wallet-info')!=null){
      this.iframeSrc = location.origin + "/polkadot-identicon/"+this.cookiesService.getCookieArray('wallet-info').address+"?size=60";
    }
  }
}
