
<div class="relative w-[500px] max-h-full bg-white bg-white dark:bg-[#121212] rounded-xl max-w-full">
    <!-- Modal content -->
    <div class="relative rounded-lg shadow bg-[#121212] bg-opacity-5 text-[#121212]" [ngClass]="{ 'opacity-50': isLoading }">
        <!-- Modal header -->
        <!-- <div class="flex items-start justify-between p-4 border-b rounded-t border-[rgba(255,255,255,0.12)]">
            <button type="button" class="dark:text-white /50 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center" data-modal-hide="defaultModal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
        </div> -->
        <!-- Modal body -->
        <div class="p-6 space-y-6">
            <img class="h-8 w-auto m-auto hidden dark:block" src="../../../../assets/logo/main-logo-white.webp" alt="">
            <img class="h-8 w-auto m-auto block dark:hidden" src="../../../../assets/logo/main-logo-black.webp" alt="">
            <ng-container *ngIf="!isWalletSelected">
                <p class="f-18 text-center mb-5">
                    <span class="text-[24px] font-bold dark:text-white">{{ 'walletpage.connect_xgame' | translate }}</span><br>
                </p>
                <ul class="bg-white rounded-xl bg-[#121212]">
                    <ng-container *ngFor="let walletItem of web3WalletArray">
                        <li *ngIf="(walletItem.title =='Talisman' || walletItem.title=='Polkadot.js') && web3WalletArray.length>0"
                        class="flex items-center justify-between p-4 my-1 cursor-pointer hover:bg-[#121212] hover:bg-opacity-10 dark:hover:bg-[#313131]" (click)="connectExtension(walletItem.extensionName)">
                            <button type="button" class="btn btn-wallet w-full">
                                <div class="flex items-center gap-2">
                                    <img class="img-fluid" style="width: 36px;" [src]="walletItem.logo.src" [alt]="walletItem.logo.alt">
                                    <span class="text-xl">{{walletItem.title}}</span>
                                </div>
                                <i class="icon-arrow-right text-base"></i>
                            </button>
                        </li>
                        <p *ngIf="walletItem.extensionName == errorWeb3Wallet.extensionName && errorWeb3Wallet.errorMessage!=''" class="text-black m-2 text-red-500">{{errorWeb3Wallet.errorMessage}}
                            <a class="dark:text-white bg-clip-text" href="{{ walletItem.installUrl }}" target="_blank">here.</a>
                        </p>
                    </ng-container>
                </ul>

            </ng-container>
            <ng-container *ngIf="isWalletSelected">
                <p class=" text-center dark:text-white">{{ 'walletpage.choose_wallet' | translate }}</p>
                <ul>
                    <li
                    (click)="onWalletSelectAndVerify(walletAccount)"
                    *ngFor="let walletAccount of walletAccounts"
                    class="bg-white hover:bg-white/10 dark:hover:bg-white/10  py-4 px-3 rounded-xl cursor-pointer my-1 border border-[#181818] border-opacity-10">
                        <span class="text-lg dark:text-white">{{walletAccount.metaName}}</span>
                        <br>
                        <span class="text-sm dark:text-white">{{walletAccount.address}}</span>
                    </li>
                </ul>
                <button (click)="goBack()" 
                class="flex m-auto border-0 py-2 px-6 focus:outline-none rounded-full bg-white dark:hover:bg-white/10">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-4">
                        <path fill-rule="evenodd" d="M11.03 3.97a.75.75 0 0 1 0 1.06l-6.22 6.22H21a.75.75 0 0 1 0 1.5H4.81l6.22 6.22a.75.75 0 1 1-1.06 1.06l-7.5-7.5a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
                    </svg>
                </button>
            </ng-container>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center p-6 space-x-2 border-t border-[rgba(255,255,255,0.12)] rounded-b">
            <p class="text-base m-auto">{{ 'walletpage.accept_terms' | translate }}</p>
        </div>
    </div>
    <div role="status" class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2 h-full w-full flex flex-wrap items-center justify-center"
    *ngIf="isLoading">
        <svg aria-hidden="true" class="w-12 h-12 text-gray-200 animate-spin " viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" style="stop-color:#9933FF;stop-opacity:1" />
                    <stop offset="100%" style="stop-color:#08CBCB;stop-opacity:1" />
                </linearGradient>
            </defs>
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="url(#gradient)"/>
        </svg>
        <span class="sr-only">{{ 'walletpage.loading' | translate }}</span>
    </div>

</div>

