export class WalletAccountsModel {
    address: string = "";
    address_display: string = "";
    metaGenesisHash: string | null | undefined = "";
    tokenSymbol: string | null | undefined = "";
    metaName: string | undefined = "";
    metaSource: string | undefined = "";
    type: string | undefined = "";
}

export class ErrorWeb3Wallet{
    extensionName: string = "";
    errorCode: string = "";
    errorMessage: string = "";
}

export class TokenBalance {
    balance: string = "";
    price: string = "";
    symbol: string = "";
    name: string = "";
    image: string = "";
}
