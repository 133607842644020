import { Component, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { polkadotIcon } from '@polkadot/ui-shared';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-polkadot-identicon',
  templateUrl: './polkadot-identicon.component.html',
  styleUrl: './polkadot-identicon.component.scss'
})
export class PolkadotIdenticonComponent implements OnChanges {
  @Input() address: string | null = '';
  @Input() width: number = 40;
  @Input() height: number = 40;
  public iconHtml: SafeHtml = '';

  constructor(
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {}

  generatePolkadotIcon() {
    if (!this.address) {
      const defaultSvg = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="${this.width}" height="${this.height}">
          <circle cx="32" cy="32" r="32" fill="#ccc" />
          <text x="32" y="35" font-size="16" text-anchor="middle" fill="#333">N/A</text>
        </svg>`;
      this.iconHtml = this.sanitizer.bypassSecurityTrustHtml(defaultSvg);
    } else {
      const icon = polkadotIcon(this.address, { isAlternative: false });
      const svgContent = icon.map(circle => {
        const { cx, cy, fill, r } = circle;
        return `<circle cx="${cx}" cy="${cy}" r="${r}" fill="${fill}" />`;
      }).join('');
      const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="${this.width}" height="${this.height}">${svgContent}</svg>`;
      this.iconHtml = this.sanitizer.bypassSecurityTrustHtml(svg);
    }
    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['address']) {
      this.generatePolkadotIcon();
    }
  }
}
