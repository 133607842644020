import { BLAKE, SIGMA } from './_blake.js';
import u64 from './_u64.js';
import { toBytes, u32, wrapConstructorWithOpts, byteSwapIfBE } from './utils.js';
// Same as SHA-512 but LE
// prettier-ignore
const B2B_IV = /* @__PURE__ */new Uint32Array([0xf3bcc908, 0x6a09e667, 0x84caa73b, 0xbb67ae85, 0xfe94f82b, 0x3c6ef372, 0x5f1d36f1, 0xa54ff53a, 0xade682d1, 0x510e527f, 0x2b3e6c1f, 0x9b05688c, 0xfb41bd6b, 0x1f83d9ab, 0x137e2179, 0x5be0cd19]);
// Temporary buffer
const BBUF = /* @__PURE__ */new Uint32Array(32);
// Mixing function G splitted in two halfs
function G1b(a, b, c, d, msg, x) {
  // NOTE: V is LE here
  const Xl = msg[x],
    Xh = msg[x + 1]; // prettier-ignore
  let Al = BBUF[2 * a],
    Ah = BBUF[2 * a + 1]; // prettier-ignore
  let Bl = BBUF[2 * b],
    Bh = BBUF[2 * b + 1]; // prettier-ignore
  let Cl = BBUF[2 * c],
    Ch = BBUF[2 * c + 1]; // prettier-ignore
  let Dl = BBUF[2 * d],
    Dh = BBUF[2 * d + 1]; // prettier-ignore
  // v[a] = (v[a] + v[b] + x) | 0;
  let ll = u64.add3L(Al, Bl, Xl);
  Ah = u64.add3H(ll, Ah, Bh, Xh);
  Al = ll | 0;
  // v[d] = rotr(v[d] ^ v[a], 32)
  ({
    Dh,
    Dl
  } = {
    Dh: Dh ^ Ah,
    Dl: Dl ^ Al
  });
  ({
    Dh,
    Dl
  } = {
    Dh: u64.rotr32H(Dh, Dl),
    Dl: u64.rotr32L(Dh, Dl)
  });
  // v[c] = (v[c] + v[d]) | 0;
  ({
    h: Ch,
    l: Cl
  } = u64.add(Ch, Cl, Dh, Dl));
  // v[b] = rotr(v[b] ^ v[c], 24)
  ({
    Bh,
    Bl
  } = {
    Bh: Bh ^ Ch,
    Bl: Bl ^ Cl
  });
  ({
    Bh,
    Bl
  } = {
    Bh: u64.rotrSH(Bh, Bl, 24),
    Bl: u64.rotrSL(Bh, Bl, 24)
  });
  BBUF[2 * a] = Al, BBUF[2 * a + 1] = Ah;
  BBUF[2 * b] = Bl, BBUF[2 * b + 1] = Bh;
  BBUF[2 * c] = Cl, BBUF[2 * c + 1] = Ch;
  BBUF[2 * d] = Dl, BBUF[2 * d + 1] = Dh;
}
function G2b(a, b, c, d, msg, x) {
  // NOTE: V is LE here
  const Xl = msg[x],
    Xh = msg[x + 1]; // prettier-ignore
  let Al = BBUF[2 * a],
    Ah = BBUF[2 * a + 1]; // prettier-ignore
  let Bl = BBUF[2 * b],
    Bh = BBUF[2 * b + 1]; // prettier-ignore
  let Cl = BBUF[2 * c],
    Ch = BBUF[2 * c + 1]; // prettier-ignore
  let Dl = BBUF[2 * d],
    Dh = BBUF[2 * d + 1]; // prettier-ignore
  // v[a] = (v[a] + v[b] + x) | 0;
  let ll = u64.add3L(Al, Bl, Xl);
  Ah = u64.add3H(ll, Ah, Bh, Xh);
  Al = ll | 0;
  // v[d] = rotr(v[d] ^ v[a], 16)
  ({
    Dh,
    Dl
  } = {
    Dh: Dh ^ Ah,
    Dl: Dl ^ Al
  });
  ({
    Dh,
    Dl
  } = {
    Dh: u64.rotrSH(Dh, Dl, 16),
    Dl: u64.rotrSL(Dh, Dl, 16)
  });
  // v[c] = (v[c] + v[d]) | 0;
  ({
    h: Ch,
    l: Cl
  } = u64.add(Ch, Cl, Dh, Dl));
  // v[b] = rotr(v[b] ^ v[c], 63)
  ({
    Bh,
    Bl
  } = {
    Bh: Bh ^ Ch,
    Bl: Bl ^ Cl
  });
  ({
    Bh,
    Bl
  } = {
    Bh: u64.rotrBH(Bh, Bl, 63),
    Bl: u64.rotrBL(Bh, Bl, 63)
  });
  BBUF[2 * a] = Al, BBUF[2 * a + 1] = Ah;
  BBUF[2 * b] = Bl, BBUF[2 * b + 1] = Bh;
  BBUF[2 * c] = Cl, BBUF[2 * c + 1] = Ch;
  BBUF[2 * d] = Dl, BBUF[2 * d + 1] = Dh;
}
export class BLAKE2b extends BLAKE {
  constructor(opts = {}) {
    super(128, opts.dkLen === undefined ? 64 : opts.dkLen, opts, 64, 16, 16);
    // Same as SHA-512, but LE
    this.v0l = B2B_IV[0] | 0;
    this.v0h = B2B_IV[1] | 0;
    this.v1l = B2B_IV[2] | 0;
    this.v1h = B2B_IV[3] | 0;
    this.v2l = B2B_IV[4] | 0;
    this.v2h = B2B_IV[5] | 0;
    this.v3l = B2B_IV[6] | 0;
    this.v3h = B2B_IV[7] | 0;
    this.v4l = B2B_IV[8] | 0;
    this.v4h = B2B_IV[9] | 0;
    this.v5l = B2B_IV[10] | 0;
    this.v5h = B2B_IV[11] | 0;
    this.v6l = B2B_IV[12] | 0;
    this.v6h = B2B_IV[13] | 0;
    this.v7l = B2B_IV[14] | 0;
    this.v7h = B2B_IV[15] | 0;
    const keyLength = opts.key ? opts.key.length : 0;
    this.v0l ^= this.outputLen | keyLength << 8 | 0x01 << 16 | 0x01 << 24;
    if (opts.salt) {
      const salt = u32(toBytes(opts.salt));
      this.v4l ^= byteSwapIfBE(salt[0]);
      this.v4h ^= byteSwapIfBE(salt[1]);
      this.v5l ^= byteSwapIfBE(salt[2]);
      this.v5h ^= byteSwapIfBE(salt[3]);
    }
    if (opts.personalization) {
      const pers = u32(toBytes(opts.personalization));
      this.v6l ^= byteSwapIfBE(pers[0]);
      this.v6h ^= byteSwapIfBE(pers[1]);
      this.v7l ^= byteSwapIfBE(pers[2]);
      this.v7h ^= byteSwapIfBE(pers[3]);
    }
    if (opts.key) {
      // Pad to blockLen and update
      const tmp = new Uint8Array(this.blockLen);
      tmp.set(toBytes(opts.key));
      this.update(tmp);
    }
  }
  // prettier-ignore
  get() {
    let {
      v0l,
      v0h,
      v1l,
      v1h,
      v2l,
      v2h,
      v3l,
      v3h,
      v4l,
      v4h,
      v5l,
      v5h,
      v6l,
      v6h,
      v7l,
      v7h
    } = this;
    return [v0l, v0h, v1l, v1h, v2l, v2h, v3l, v3h, v4l, v4h, v5l, v5h, v6l, v6h, v7l, v7h];
  }
  // prettier-ignore
  set(v0l, v0h, v1l, v1h, v2l, v2h, v3l, v3h, v4l, v4h, v5l, v5h, v6l, v6h, v7l, v7h) {
    this.v0l = v0l | 0;
    this.v0h = v0h | 0;
    this.v1l = v1l | 0;
    this.v1h = v1h | 0;
    this.v2l = v2l | 0;
    this.v2h = v2h | 0;
    this.v3l = v3l | 0;
    this.v3h = v3h | 0;
    this.v4l = v4l | 0;
    this.v4h = v4h | 0;
    this.v5l = v5l | 0;
    this.v5h = v5h | 0;
    this.v6l = v6l | 0;
    this.v6h = v6h | 0;
    this.v7l = v7l | 0;
    this.v7h = v7h | 0;
  }
  compress(msg, offset, isLast) {
    this.get().forEach((v, i) => BBUF[i] = v); // First half from state.
    BBUF.set(B2B_IV, 16); // Second half from IV.
    let {
      h,
      l
    } = u64.fromBig(BigInt(this.length));
    BBUF[24] = B2B_IV[8] ^ l; // Low word of the offset.
    BBUF[25] = B2B_IV[9] ^ h; // High word.
    // Invert all bits for last block
    if (isLast) {
      BBUF[28] = ~BBUF[28];
      BBUF[29] = ~BBUF[29];
    }
    let j = 0;
    const s = SIGMA;
    for (let i = 0; i < 12; i++) {
      G1b(0, 4, 8, 12, msg, offset + 2 * s[j++]);
      G2b(0, 4, 8, 12, msg, offset + 2 * s[j++]);
      G1b(1, 5, 9, 13, msg, offset + 2 * s[j++]);
      G2b(1, 5, 9, 13, msg, offset + 2 * s[j++]);
      G1b(2, 6, 10, 14, msg, offset + 2 * s[j++]);
      G2b(2, 6, 10, 14, msg, offset + 2 * s[j++]);
      G1b(3, 7, 11, 15, msg, offset + 2 * s[j++]);
      G2b(3, 7, 11, 15, msg, offset + 2 * s[j++]);
      G1b(0, 5, 10, 15, msg, offset + 2 * s[j++]);
      G2b(0, 5, 10, 15, msg, offset + 2 * s[j++]);
      G1b(1, 6, 11, 12, msg, offset + 2 * s[j++]);
      G2b(1, 6, 11, 12, msg, offset + 2 * s[j++]);
      G1b(2, 7, 8, 13, msg, offset + 2 * s[j++]);
      G2b(2, 7, 8, 13, msg, offset + 2 * s[j++]);
      G1b(3, 4, 9, 14, msg, offset + 2 * s[j++]);
      G2b(3, 4, 9, 14, msg, offset + 2 * s[j++]);
    }
    this.v0l ^= BBUF[0] ^ BBUF[16];
    this.v0h ^= BBUF[1] ^ BBUF[17];
    this.v1l ^= BBUF[2] ^ BBUF[18];
    this.v1h ^= BBUF[3] ^ BBUF[19];
    this.v2l ^= BBUF[4] ^ BBUF[20];
    this.v2h ^= BBUF[5] ^ BBUF[21];
    this.v3l ^= BBUF[6] ^ BBUF[22];
    this.v3h ^= BBUF[7] ^ BBUF[23];
    this.v4l ^= BBUF[8] ^ BBUF[24];
    this.v4h ^= BBUF[9] ^ BBUF[25];
    this.v5l ^= BBUF[10] ^ BBUF[26];
    this.v5h ^= BBUF[11] ^ BBUF[27];
    this.v6l ^= BBUF[12] ^ BBUF[28];
    this.v6h ^= BBUF[13] ^ BBUF[29];
    this.v7l ^= BBUF[14] ^ BBUF[30];
    this.v7h ^= BBUF[15] ^ BBUF[31];
    BBUF.fill(0);
  }
  destroy() {
    this.destroyed = true;
    this.buffer32.fill(0);
    this.set(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);
  }
}
/**
 * BLAKE2b - optimized for 64-bit platforms. JS doesn't have uint64, so it's slower than BLAKE2s.
 * @param msg - message that would be hashed
 * @param opts - dkLen, key, salt, personalization
 */
export const blake2b = /* @__PURE__ */wrapConstructorWithOpts(opts => new BLAKE2b(opts));
