import { Injectable, OnDestroy } from '@angular/core';
import { BaseDotsamaWallet, PolkadotjsWallet, TalismanWallet, Wallet, getWalletBySource,getWallets } from '@talismn/connect-wallets';
import { WalletAccountsModel } from '../../model/wallet.model';
import { hexToU8a, isHex, stringToHex, stringToU8a, u8aToHex } from '@polkadot/util';
import { cryptoWaitReady, decodeAddress, signatureVerify } from '@polkadot/util-crypto';
import { Keyring, encodeAddress } from '@polkadot/keyring';
import { formatBalance } from '@polkadot/util';
import { CookiesService } from '../cookies/cookies.service';
import { WsProvider } from '@polkadot/rpc-provider';
import { ApiPromise } from '@polkadot/api';
import { environment } from '../../../../environments/environment';
import { AccountData } from '@polkadot/types/interfaces';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PolkadotService implements OnDestroy{

  constructor(
    private cookiesService: CookiesService
  ) { }

  api: any;
  private balanceSubject = new BehaviorSubject<string>('0');
  unsubscribe: (() => void) | undefined;

  ngOnDestroy() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
    if (this.api) {
      this.disconnect();
    }
  }
    async connect() {
        if (!this.api) {
            const provider = new WsProvider(this.cookiesService.getCookieArray('network')!=undefined? this.cookiesService.getCookieArray('network').wsProviderEndpoint :environment.network[0].networks[0].wsProviderEndpoint);
            this.api = await ApiPromise.create({ provider });
            return;
        }
    }

    async disconnect() {
        await this.api.disconnect();
    }

  getAllExtension(): any{
    const supportedWallets: Wallet[] = getWallets();
    return supportedWallets;
  }

  connectExtension(extension: string): Promise<any> {
    return new Promise((resolve, reject) => {
      let wallet: TalismanWallet | PolkadotjsWallet | null = null;
      if (extension === 'talisman') {
        wallet = getWalletBySource('talisman') as TalismanWallet;

      } else if (extension === 'polkadot-js') {
        wallet = getWalletBySource('polkadot-js') as PolkadotjsWallet;
      }

      if (wallet) {
        wallet.enable('Xode')
          .then(() => {
            wallet?.subscribeAccounts((accounts: any) => {
              const walletAccounts: any[] = [];
              accounts.forEach((account: any) => {
                walletAccounts.push({
                  address: account.address,
                  address_display: account.address.substring(0, 5) + "..." + account.address.substring(account.address.length - 5),
                  metaGenesisHash: '',
                  metaName: account.name,
                  tokenSymbol: "",
                  metaSource: account.wallet.extensionName,
                  type: ''
                });
              });
              resolve(walletAccounts);
            }).catch((error: any) => {
              console.log(error);
              reject(error);
            });
          })
          .catch((error: any) => {
            reject(error);
          });
      } else {
        reject(new Error('Wallet not found'));
      }

    });
  }

  async signAndVerify(walletAccount: WalletAccountsModel): Promise<any> {
    try {
      await cryptoWaitReady();
      const wallet = getWalletBySource(walletAccount.metaSource);
      const accounts = await wallet?.getAccounts();
      const currentAccount = accounts?.find((acc) => acc.address === walletAccount.address);

      if (!currentAccount) {
        console.error('Account not found.');
        return false;
      }

      const signer = currentAccount.wallet?.signer;
      const message: string = 'Please sign before you proceed. Thank you!';

      const { signature } = await signer.signRaw({
        type: 'bytes',
        data: stringToHex(message),
        address: currentAccount.address,
      });

      const { isValid } = signatureVerify(message, signature, currentAccount.address);

      return isValid;
    } catch (err) {
      console.error('Error during signAndVerify:', {
        error: err,
        walletAccount,
      });
      return false;
    }
  }
  async generateKeypair(address: string): Promise<string> {
    const keyring = new Keyring({ type: 'sr25519', ss58Format: 0 });
    const hexPair = keyring.addFromAddress(address);

    return hexPair.address;
  }

    balanceFormatter = (
        decimals: any,
        token: any,
        balance: any
    ) => {
        formatBalance.setDefaults({ decimals: decimals, unit: token[0] });
        formatBalance.getDefaults();
        const free = formatBalance(balance, { forceUnit: token[0], withUnit: false });
        const balances = free.split(',').join('');
        const parsedBalance = parseFloat(balances.replace(/,/g, '')).toFixed(4);
        return parsedBalance;
    }

    isAddressValid(walletAddress: string) {
        try {
            encodeAddress(
                isHex(walletAddress)
                ? hexToU8a(walletAddress)
                : decodeAddress(walletAddress)
            );
            return true;
        } catch (error) {
            console.error(error);
            return false;
        }
    }

    public async signExtrinsics(
        extrinsics: string
    ): Promise<any> {
        try {
            if (!this.api) await this.connect();
            const walletInfo = this.cookiesService.getCookieArray("wallet-info");
            const sender = walletInfo.address;

            let wallet: TalismanWallet | PolkadotjsWallet | null = null;

            if (walletInfo.metaSource === 'talisman') {
                wallet = getWalletBySource('talisman') as TalismanWallet;
            } else if (walletInfo.metaSource === 'polkadot-js') {
                wallet = getWalletBySource('polkadot-js') as PolkadotjsWallet;
            }

            if (wallet) {
                await wallet.enable('XGame');
                const accounts = await wallet.getAccounts();
                if (accounts) {
                    const currentAccount = accounts.find((acc) => acc.address === sender);
                    if (!currentAccount) {
                        console.error('Account not found.');
                        return;
                    }
                    const signer = currentAccount.wallet?.signer;
                    const unsignedExtrinsics = await this.api.tx(extrinsics);
                    const signedExtrinsics = await unsignedExtrinsics.signAsync(sender, { signer });
                    if (signedExtrinsics) {
                        return signedExtrinsics.toHex();
                    }
                }
            }
        } catch (error: any) {
            return Error(error.message);
        }
    }

    public getBalance(): Observable<string> {
        return this.balanceSubject.asObservable();
    }

    public async subscribeToBalance(address: string): Promise<void> {
        if (!this.api) {
            await this.connect();
        }
        try {
            this.unsubscribe = await this.api.query.system.account(address, ({ data }: { data: AccountData }) => {
                const { free: balance } = data;
                const amount = this.balanceFormatter(12, 'XON', balance);
                this.balanceSubject.next(amount);
            });
        } catch (error) {
            console.error('Error subscribing to balance:', error);
        }
    }

    // public async subscribeToNewBlocks(): Promise<void> {
    //     if (!this.api) {
    //         await this.connect();
    //     }

    //     await this.api.rpc.chain.subscribeNewHeads(async (lastHeader: any) => {
    //         console.log(
    //             `Last block #${lastHeader.number} has hash ${lastHeader.hash}`
    //         );
    //         // Retrieve the latest signed block
    //         const signedBlock = await this.api.rpc.chain.getBlock(lastHeader.hash);

    //         // Get the API and events at a specific block
    //         const apiAt = await this.api.at(signedBlock.block.header.hash);
    //         const allRecords = await apiAt.query.system.events();

    //         console.log(`All Records: ${allRecords}`);
    //         // Map between the extrinsics and events
    //         signedBlock.block.extrinsics.forEach((extrinsic: any, index: number) => {
    //             const { method: { method, section } } = extrinsic;

    //             allRecords
    //                 // Filter specific events based on the phase and the index of the extrinsic
    //                 .filter(({ phase }: { phase: any }) =>
    //                     phase.isApplyExtrinsic &&
    //                     phase.asApplyExtrinsic.eq(index)
    //                 )
    //                 // Test the events against the specific types we're looking for
    //                 .forEach(({ event, phase }: { event: any, phase: any }) => {
    //                     console.log(`Event: ${event}`);
    //                     console.log(`Phase: ${phase}`);
    //                     if (this.api.events.system.ExtrinsicSuccess.is(event)) {
    //                         // Extract the data for this event
    //                         const [dispatchInfo] = event.data;
    //                         console.log(`${section}.${method}:: ExtrinsicSuccess:: ${JSON.stringify(dispatchInfo.toHuman())}`);
    //                     } else if (this.api.events.system.ExtrinsicFailed.is(event)) {
    //                         // Extract the data for this event
    //                         const [dispatchError, dispatchInfo] = event.data;
    //                         let errorInfo;

    //                         // Decode the error
    //                         if (dispatchError.isModule) {
    //                             // For module errors, we lookup the error
    //                             const decoded = this.api.registry.findMetaError(dispatchError.asModule);
    //                             errorInfo = `${decoded.section}.${decoded.name}`;
    //                         } else {
    //                             // For other errors
    //                             errorInfo = dispatchError.toString();
    //                         }
    //                         console.log(`${section}.${method}:: ExtrinsicFailed:: ${errorInfo}`);
    //                     }
    //                 });
    //         });
    //     });
    // }
}
